body,
html {
    margin: 0;
    padding: 0;
}

.container {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
    background-image: url("../image/bg.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    background-position: right, left; 
}

.connect-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.titles-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.beanland-title {
    color: white;
    font-weight: 600;
}

.header-container {
    margin-top: 5rem;
}

.beanland-connect-text {
    color: white;
    font-weight: 100;
}

.epic-logo {
    height: 50px;
    width: 48px;
    padding: 10px;
}

.epic-login-btn {
    background-color: black;
    color: white;
    font-weight: 300;
    border-radius: 12px;
    margin: 12px;
    padding-right: 22px;
    padding-left: 10px;
    min-width: 150px;
    min-height: 35px;
}

.epic-login-btn:hover {
    background-color: rgb(55, 55, 55);
    color: white;
}

.metamask-connect-btn {
    width: 310px;
    height: 60px;
    margin-top: 20px;
}

.metamask-connect-btn:hover {
    cursor: pointer;
}

.metamask-connect-container {
    display: inline-block;
}

.epic-connect-text {
    color: white;
    font-weight: 200;
    font-size: 10px;
    margin-top: 15px;
}

.connect-accounts-btn {
    border-color: rgb(40, 255, 21);
    border-width: 2px;
    padding: 15px;
    font-weight: 800;
    color: rgb(40, 255, 21);
    margin-top: 25px;
}

.connect-accounts-btn:hover {
    background-color: rgb(24, 213, 7);
    border-color: rgb(24, 213, 7);
    margin-top: 25px;
}

.alert-success {
    background-color: rgb(24, 213, 7);
    border-color: rgb(24, 213, 7);
    color: white;
    margin-top: 25px;
}